<template>
  <div>
    <pcHeaders></pcHeaders>

    <div class="loginBanner">
      <div class="cent">
        <div>
          <div class="title">安徽继续教育网</div>

          <div class="sof">
            <img src="../assets/images/loginBc.png"
                 alt="">
          </div>
        </div>
        <div class="xian">
        </div>

        <div class="forms">
          <div class="qs">
            账号
          </div>
          <el-input v-model="userInfo.userName"
                    class="sr"
                    placeholder="用户名/身份证号/手机号"></el-input>

          <div class="qs"
               style="margin-top: 20px;">
            密码
          </div>
          <el-input type="password"
                    v-model="userInfo.userPasswd"
                    class="sr"
                    placeholder="请输入密码"></el-input>
          <el-button class="loginBtn"
                     @click="login">立即登录</el-button>

          <div class="lognBtm">
            <div class="wj"
                 @click="showDig">忘记密码</div>
            <div class="wj"
                 style="color: #436CF3;"
                 @click="goRegist">快速注册</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="重置密码"
               :visible.sync="dialogVisible"
               width="400px"
               :close-on-click-modal="false">
      <div style="margin: 0 auto;width: 80%;"
           v-if="nax == 1">
        <div class="forms">
          <div class="qs">
            手机号:
          </div>
          <el-input v-model="resetting.bindMoblie"
                    class="sr"
                    style="margin-top: 8px;"
                    placeholder="请输入手机号"></el-input>

          <div class="qs"
               style="margin-top: 20px;">
            验证码:
          </div>
          <div style="display: flex;">
            <el-input type="text"
                      v-model="resetting.checkcode"
                      class="sr"
                      style="margin-top: 8px;width:190px ;"
                      placeholder="请输入验证码"></el-input>
            <img style="height: 40px;width: 90px;margin-top: 8px;margin-left: 10px;cursor: pointer;"
                 @click="captchaImage"
                 :src="'data:image/png;base64,' + codeImgInfo.img">
          </div>
          <div style="display: flex;justify-content: center;">

            <el-button type="primary"
                       style="margin:  20px 0;"
                       @click="nextOne(2)">下一步</el-button>
          </div>

        </div>
      </div>

      <div style="margin: 0 auto;width: 80%;"
           v-if="nax == 2">
        <div class="forms">
          <div class="qs"
               style="margin-top: 20px;">
            短信验证码:
          </div>
          <div style="display: flex;">
            <el-input type="text"
                      v-model="dxCode"
                      class="sr"
                      style="margin-top: 8px;width:180px;margin-right: 10px;"
                      placeholder="请输入验证码"></el-input>
            <el-button type="primary"
                       style="margin-top:8px;text-align: center;"
                       v-if="!isCounting"
                       @click="getCode">{{ buttonText }}</el-button>
            <el-button type="primary"
                       style="margin-top:8px;text-align: center;"
                       v-if="isCounting">{{ countdown }}s后重新发送</el-button>
          </div>
          <div style="display: flex;justify-content: center;">

            <el-button type="primary"
                       style="margin:  20px 0;"
                       @click="nextOne(3)">下一步</el-button>
          </div>

        </div>
      </div>

      <div style="margin: 0 auto;width: 80%;"
           v-if="nax == 3">
        <div class="forms">
          <div style="color: #436CF3;">
            姓名：{{ reseUserInfo.realname }}
          </div>
          <div style="color: #436CF3;">
            身份证号：{{ reseUserInfo.cardno }}
          </div>
          <div class="qs"
               style="margin-top: 8px;">
            请输入新密码:
          </div>
          <el-input v-model="updataPsd.newPass1"
                    class="sr"
                    style="margin-top: 8px;width: 270px;"
                    placeholder="请输入新密码"></el-input>

          <div class="qs"
               style="margin-top: 8px;">
            确认新密码:
          </div>
          <el-input v-model="updataPsd.newPass2"
                    class="sr"
                    style="margin-top: 8px;width: 270px;"
                    placeholder="确认新密码"></el-input>

          <div style="display: flex;justify-content: center;">

            <el-button type="primary"
                       style="margin:  20px 0;"
                       @click="subMit">完成</el-button>
          </div>

        </div>
      </div>
    </el-dialog>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../views/component/head.vue';
import pcBottom from '../views/component/bottom.vue';
import { mapMutations } from 'vuex';
import { captchaImage, login, toCheckPhone, sendDx3, toCheckPhone2, toResetPassword3, toUpdatePass } from '@/api/login.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      userInfo: {
        userName: '',
        userPasswd: ''
      },
      dialogVisible: false,
      nax: 1, //忘记密码下一步
      codeImgInfo: {},
      resetting: {
        bindMoblie: '',
        checkcode: ''
      },
      resePhoneInfo: {}, //第二步使用
      countdown: 60, // 默认倒计时为60秒
      isCounting: false, // 标记是否正在进行倒计时
      buttonText: '获取验证码', // 按钮文字
      dxCode: '',
      reseUserInfo: {}, //修改密码的用户资料
      updataPsd: {
        newPass1: '',
        newPass2: ''
      },
      restPath:'',
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.fromPath = from.path; 
    });
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    ...mapMutations('m_user', ['updateToken','REAL_NAME']),
    goRegist() {
      this.$router.push('/regist');
    },
    //更新密码
    subMit() {
      if (this.updataPsd.newPass1 !== this.updataPsd.newPass2) {
        this.$message('两次密码不一致！');
        return false;
      }
      (this.updataPsd.logo = this.resetting.logo),
        (this.updataPsd.USERID_TO_RESET_PASS = this.resePhoneInfo.USERID_TO_RESET_PASS),
        toUpdatePass(this.updataPsd).then(res => {
          console.log(res.data.data);
          if (res.data.data.code !== '1') {
            this.$message(res.data.data.msg);
            return false;
          } else {
            this.$message({
              message: '更新密码成功！请登录',
              type: 'success'
            });
            this.dialogVisible = false;
          }
        });
    },
    //获取手机短信
    getCode() {
      if (!this.isCounting) {
        // this.getImgCode();
        let data = {
          logo: this.resetting.logo,
          bindMoblie: this.resetting.bindMoblie,
          checkcode: this.resetting.checkcode,
          USERID_TO_RESET_PASS: this.resePhoneInfo.USERID_TO_RESET_PASS,
          bz: this.resePhoneInfo.bz,
          USERID_TO_RESET_PASS_valideCode: this.resePhoneInfo.USERID_TO_RESET_PASS_valideCode,
          imgCode: this.resePhoneInfo.imgCode
        };
        // 这里可以写发送验证码的逻辑
        sendDx3(data).then(res => {
          console.log(res.data.data.code);
          if (res.data.data.code == '1') {
            this.startCountDown();
            this.$message({
              message: '短信验证码已发送至手机，请注意查收！',
              type: 'success'
            });
          } else {
            this.$message(res.data.data.msg);
          }
        });
      }
    },
    startCountDown() {
      let timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(timer);

          this.resetButtonState();
        }
      }, 1000);

      this.isCounting = true;
    },

    resetButtonState() {
      this.isCounting = false;
      this.countdown = 60;
      this.buttonText = '获取验证码';
    },
    nextOne(id) {
      if (id == 2) {
        if (this.resetting.bindMoblie == '' || this.resetting.checkcode == '') {
          this.$message('请填写完全！');
          return false;
        }
        this.resetting.logo = this.codeImgInfo.uuid;
        toCheckPhone(this.resetting).then(res => {
          if (res.data.data.code == '1') {
            this.resePhoneInfo = res.data.data;
            this.nax = id;
          } else {
            this.$message(res.data.data.msg);

            return false;
          }
        });
      } else if (id == 3) {
        if (this.dxCode == '') {
          this.$message('短信验证码为空！');
          return false;
        }
        let data = {
          logo: this.resetting.logo,
          check_code: this.dxCode
        };
        toCheckPhone2(data).then(res => {
          console.log(res.data.data);
          if (res.data.data.code == '1') {
            let query = {
              logo: this.resetting.logo,
              USERID_TO_RESET_PASS: this.resePhoneInfo.USERID_TO_RESET_PASS
            };
            toResetPassword3(query).then(respos => {
              if (respos.data.data.code == '1') {
                this.reseUserInfo = respos.data.data;
                this.nax = id;
              } else {
                this.$message(respos.data.data.msg);
                return false;
              }
            });
          } else {
            this.$message(res.data.data.msg);
            return false;
          }
        });
      }
    },
    captchaImage() {
      captchaImage().then(res => {
        this.codeImgInfo = res.data;
      });
    },
    login() {
      if (this.userInfo.userName == '' || this.userInfo.userName == undefined) {
        this.$message({
          message: '请输入账号！',
          type: 'warning'
        });
        return false;
      } else if (this.userInfo.userPasswd == '' || this.userInfo.userPasswd == undefined) {
        this.$message({
          message: '请输入密码！',
          type: 'warning'
        });
        return false;
      }
      login(this.userInfo).then(res => {
        if (res.data.msg == '登录成功') {
          this.updateToken(res.data.token);
          this.REAL_NAME(res.data.realname)
          // 拿到密钥存在本地存储
          localStorage.setItem('token', res.data.token);
          this.$message({
            message: res.data.msg,
            type: 'success'
          });

          console.log(this.fromPath,'买东西');
          if (this.fromPath == '/toPayOrder') {
            this.$router.replace('/studyMain')
            return false
          }
          if (this.fromPath == '/regist') {
            this.$router.push('/studyMain')
          }else{
            this.$router.back()
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    showDig() {
      this.nax = 1;
      captchaImage().then(res => {
        this.codeImgInfo = res.data;
        this.dialogVisible = true;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.el-form-item /deep/ .item__content {
  margin-left: 0 !important;
}
.loginBanner {
  width: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  padding-top: 46px;
  font-family: Regular;
  padding-bottom: 200px;
  .cent {
    width: 900px;
    height: 380px;
    background: #ffffff;
    opacity: 1;
    border-radius: 0px;
    display: flex;
    padding: 57px 50px;
    .title {
      font-size: 24px;
      font-family: bold;
      color: #333333;
      text-align: center;
    }
    .sof {
      margin-top: 32px;
    }
    img {
      width: 380px;
      height: 201px;
    }
    .xian {
      margin: 44px 0 0 61px;
      height: 180px;
      border: 1px solid #eeeeee;
    }
    .forms {
      margin-left: 60px;
      .qs {
        font-size: 16px;
        font-family: Medium;
        color: #333333;
      }
      .sr {
        margin-top: 8px;
        width: 300px;
        height: 42px;
        opacity: 1;
        border-radius: 4px;
        color: #a3a3a3;
      }

      .loginBtn {
        margin-top: 28px;
        width: 300px;
        height: 46px;
        background: #436cf3;
        opacity: 1;
        border-radius: 4px;
        color: #fff;
      }
      .lognBtm {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        .wj {
          color: #a3a3a3;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>